html,
body,
#root,
#app-container {
  height: 100%;
  background-color: #252a31;
}

.goog-te-gadget {
  display: none;
}

#list {
  margin: 0px;
  width: 100%;
  height: calc(100vh - 50px);
}

#search {
  width: 300px;
}

.selectfilter {
  margin-right: 3px;
}

.row__container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.row__container--notvisible {
  display: none !important;
}

.row__desc {
  padding-right: 15px;
}

.row__text {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.row__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.row__title {
  min-width: 100px;
  margin-right: 15px;
}

.row__collapse {
  width: 100%;
}

.row__content {
  flex: 0 0 400px;
}

.row__images {
  padding: 0px 25px;
}

.row__loader {
  padding: 35px 0px;
}

.bp5-collapse {
  width: 100%;
}

.bp5-collapse-body {
  width: 100%;
}

.nav__translate {
  width: 100px;
}

.error {
  width: 100%;
  height: 300px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
